body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf0f5 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  display: block;
}

.navbar {
  min-height: 50px !important;
}

.navbar-nti {
  box-sizing: border-box;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #1887b4;
}

.logoheader {
  max-width: 50px;
  max-height: 50px;
}

.navbar-brand {
  color: #EEE !important;
  height: 50px;
  line-height: 20px;
  padding: 16px 10px 9px;
}

.nome-sistema {
  font-family: 'Varela Round', sans-serif;
  font-weight: lighter;
  font-size: 22px !important;
}

.hidden-print {
  height: 54px;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #fff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  padding-top: 10px;
}


.box.box-primary {
  border-top: 3px solid #1887b4;
}

.box .box-body {
  border-radius: 0 0 3px 3px;
  padding: 0 10px 10px;
}

h4 {
  line-height: 1.1 !important;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500 !important;
  unicode-bidi: isolate;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 18px !important;
}

.box .box-header {
  color: #444;
  display: flex;
  padding: 0 10px 10px;
  position: relative;
}

.control-label {
  text-align: left;
  margin-bottom: 0;
  padding-top: 7px;
  color: #333;
  font-weight: 700;
  font-size: 13px;
}

@media (min-width: 768px) {
  .control-label {
    text-align: right;
  }

}

.btn-success {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}

span {
  color: #f00;
}

.form-control, output {
  font-size: 14px !important;
  line-height: 1.42857 !important;
  color: #555 !important;
  display: block !important;
  width: 100% !important;
  height: 34px !important;
  padding: 6px 12px !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.main-footer {
  width: 100%;
  background-color: #1887b4;
  color: #eee;
  padding: 10px 0 0;
  margin: 0;
  float: left;
  height: 70px;
  position: fixed;
  bottom: 0;
}

.main-footer a {
  color: #eee;
  text-decoration: underline;
  font-weight: 700;
}

.captcha {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 302px;
  height: 74px;
  border-radius: 2px;
}

.captcha-checkbox {
  border-radius: 2px;
  width: 28px;
  height: 28px;
  margin-left: 0.45rem;
  margin-top: 1rem;
  margin-right: 0.75rem;
  appearance: none; /* Remove o estilo padrão */
  border: 2px solid #bbb; /* Borda inicial */
  animation: ease all;
}

.captcha-checkbox2 {
  border-radius: 2px;
  width: 28px;
  height: 28px;
  margin-left: 0.45rem;
  margin-top: 1rem;
  margin-right: 0.75rem;
  animation: ease all;
}

.captcha-content {
  font-size: 14px;
  font-weight: 500;
}

.img-captcha {
  cursor: pointer;
}

.captcha-checkbox:checked {
  border-color: transparent; /* Torna a borda exterior transparente */
}

/* Estilo do fundo e check em "v" */
.captcha-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Torna o fundo transparente */
}

.captcha-checkbox:checked::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 22px;
  margin-left: 6px;
  border: solid #4CAF50; /* Cor do check em "v" */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg); /* Rotaciona para formar o "v" */
}

.custom-checkbox {
  position: absolute;
  opacity: 0; /* Esconder o checkbox padrão */
}

.checkbox-label {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin: 1rem 0.45rem;
  border: 2px solid #bbb; /* Borda inicial */
  border-radius: 2px; /* Borda arredondada */
  transition: border 0.3s ease-in-out;
}

/* Estilo do label ao ser clicado */
.checkbox-label::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid #d3d3d3; /* Estilo do círculo */
  border-radius: 50%;
  border-top: 3px solid #4CAF50; /* Cor do indicador de loading */
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/* Checkbox marcado */
.custom-checkbox:checked + .checkbox-label {
  border-color: transparent; /* Torna a borda externa transparente */
}

.custom-checkbox:checked + .checkbox-label::before {
  animation: loading 0.6s ease-in-out forwards, hideCircle 0.2s ease-in-out 0.2s forwards; /* Animação de loading e depois esconde */
  opacity: 1;
  transform: scale(1);
}

/* Animação de loading */
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animação para esconder o círculo */
@keyframes hideCircle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0; /* Ocultar o círculo após o loading */
  }
}

/* Estilo do "check" */
.custom-checkbox:checked + .checkbox-label::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 22px;
  border: solid #4CAF50; /* Cor do "v" */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  margin-left: 6px;
  margin-top: -3px;
  opacity: 0; /* Esconder inicialmente */
  transition: opacity 0.1s ease-in-out 0.8s; /* Delay de 0.8s para exibir após esconder o círculo */
}

/* Exibir o "v" após a animação */
.custom-checkbox:checked + .checkbox-label::after {
  opacity: 1;
}

.danger-text {
  color: #a94442;
  font-size: 13px;
}

.alert-success {
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
  color: #3c763d !important;
  font-size: 13px;
}